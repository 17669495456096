<template>
  <SelaCropper
    :movable="false"
    :initial-image="image"
    :resizable="false"
    drag-mode="move"
    @cropped="emit('cropped', $event)"
  >
    <template #button="picker">
      <div
        class="flex items-center px-1 mt-1 border rounded cursor-pointer sela-text-field"
        @click="picker.pickImage"
      >
        <div
          class="flex-auto font-light text-secondary ltr:text-left rtl:text-right"
        >
          {{ t('change_picture') }}
        </div>

        <div class="w-5"></div>
        <v-icon icon="mdi mdi-attachment" :size="20" />
      </div>
    </template>
  </SelaCropper>
</template>

<script setup lang="ts">
const emit = defineEmits(['cropped'])

const { data } = useAuth()
const { t } = useI18n()

const image = computed(() => {
  return useHttpUrl(data.value?.picture)
})
</script>
